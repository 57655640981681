import React from 'react';
import PropTypes from 'prop-types';

import StyledInput from './Input.style';

const defaultProps = {
  error: false,
  block: false,
  className: '',
  customStyled: '',
  onChange() { },
  onFocus() { },
  onKeyUp() { },
  onKeyDown() { },
  onBlur() { },
  id: '',
  inputMode: null,
  placeholder: '',
  defaultValue: null,
  size: 'medium',
  type: 'text',
  value: null,
  name: '',
  disabled: false,
  readOnly: false,
  required: false,
  maxLength: '',
};

const propTypes = {
  error: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  customStyled: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  inputMode: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  size: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.string,
};

export function Input({
  error,
  block,
  className,
  customStyled,
  onChange,
  onFocus,
  onKeyUp,
  onKeyDown,
  onBlur,
  id,
  inputMode,
  placeholder,
  defaultValue,
  size,
  type,
  name,
  value,
  disabled,
  readOnly,
  required,
  maxLength,
  ...props
}) {
  const inputProps = {
    error,
    block,
    className,
    customStyled,
    onChange,
    onFocus,
    onKeyUp,
    onKeyDown,
    onBlur,
    id,
    inputMode,
    placeholder,
    size,
    type,
    name,
    disabled,
    readOnly,
    required,
    maxLength,
  };

  // dynamically collect all data attributes for injecting on to input element
  const dataAttributes = Object.keys(props)
    .filter(key => key.indexOf('data-') === 0)
    .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});

  const isControlled = value !== null;

  if (isControlled) {
    inputProps.value = value;
  } else {
    inputProps.defaultValue = defaultValue;
  }

  return (
    <StyledInput
      {...dataAttributes}
      {...inputProps}
      data-error={error}
    />
  );
}

Input.defaultProps = defaultProps;
Input.propTypes = propTypes;

export default Input;
