import styled, { css } from 'styled-components';

import { disabledCSS, field, focusState } from '../Form.style';

export const select = css`
  ${field}

  appearance: none;
  background-image: url('../../../../static/images/icons/SelectArrow.svg');
  padding-right: 2rem;

  ${({ size }) => !['xs', 'small'].includes(size) && css`
    background-position: right 1rem center;
    background-size: 0.9375rem;
  `}

  ${({ size }) => size === 'xs' && css`
    background-position: right 0.5rem center;
    background-size: 0.625rem;
    border: 1px solid ${({ theme }) => theme.COLOR_GRAY};
    padding: 0 1.5rem 0 0.5rem;
    border-radius: 0;
  `}

  ${({ size }) => size === 'small' && css`
    background-position: right 0.5rem center;
    background-size: 0.75rem;
    border-radius: 0;
    height: 2rem;
    padding: 0 2rem 0 1rem;
  `}

  ${({ disabled }) => disabled && disabledCSS}

  ${({ error, theme }) => error && css`
    border-color: ${theme.COLOR_DANGER};
  `}

  &:disabled {
    ${disabledCSS}
  }
  ${focusState}
`;

export default styled.select`
  ${select};

  select::-ms-expand {
    display: none;
  }
`;
