import React from 'react';
import PropTypes from 'prop-types';
import withDictionary from '../../../../hocs/withDictionary';

import StyledLabel from './Label.style';

export function Label({
  children,
  dictionary,
  responsiveHeight,
  addColon,
  ...props
}) {
  return (
    <StyledLabel
      {...props}
      data-dictionary={props.dictionary}
      responsiveHeight={responsiveHeight}
    >
      {children}{addColon ? ':' : ''}
    </StyledLabel>
  );
}

Label.propTypes = {
  addColon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  dictionary: PropTypes.string,
  padding: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
  responsiveHeight: PropTypes.bool,
};

Label.defaultProps = {
  addColon: true,
  className: '',
  dictionary: '',
  padding: '',
  size: '0.875rem',
  weight: 'regular',
  responsiveHeight: false,
};

export default withDictionary(Label, { name: 'Label' });
