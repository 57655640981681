import PropTypes from 'prop-types';
import React, { memo } from 'react';

import {
  Group,
  Label,
  RadioInput,
  RadioDisplay,
  LabelText,
} from './Radio.style';

const defaultProps = {
  color: '',
  checked: false,
  value: '',
  activeBackgroundColor: '',
  backgroundColor: '',
  'data-testid': null,
  disabled: false,
};

const propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  backgroundColor: PropTypes.string,
  activeBackgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  'data-testid': PropTypes.string,
};

function Radio(props) {
  const inputProps = {
    checked: props.checked,
    id: props.id,
    onChange: props.onChange,
    name: props.name,
    value: props.value,
    backgroundColor: props.backgroundColor,
    activeBackgroundColor: props.activeBackgroundColor,
    disabled: props.disabled,
  };

  return (
    <Group>
      <Label
        htmlFor={props.id}
        data-testid={props['data-testid']}
      >
        <RadioInput
          {...inputProps}
          data-testid={
            props['data-testid'] ? `${props['data-testid']}__input` : null
          }
        />
        <RadioDisplay
          {...inputProps}
          data-testid={
            props['data-testid'] ? `${props['data-testid']}__styling` : null
          }
          tabIndex="0"
        />
        <LabelText
          color={props.color}
          data-testid={
            props['data-testid'] ? `${props['data-testid']}__text` : null
          }
        >
          {props.label}
        </LabelText>
      </Label>
    </Group>
  );
}

Radio.defaultProps = defaultProps;
Radio.propTypes = propTypes;

export default memo(Radio);
