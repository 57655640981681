import styled from 'styled-components';

import { above } from '../../../../styles/libs';
import { focusState } from '../Form.style';

export const Group = styled.div``;

export const RadioInput = styled.input.attrs(props => ({
  type: 'radio',
}))`
  display: none;

  &:checked + span {
    background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
    border: 0.375rem solid ${props => (props.activeBackgroundColor ? props.activeBackgroundColor : props.theme.COLOR_WHITE)};
  }
`;

export const RadioDisplay = styled.span`
  display: inline-block;
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 1px solid ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
  border-radius: 0.65625rem;
  transition: all 250ms ease;
  ${focusState}
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
`;

export const LabelText = styled.span`
  vertical-align: middle;
  color: ${props => props.color};
  font-size: 0.8125rem;

  ${({ theme }) => above(theme.DESKTOP)`
    font-size: 0.875rem;
  `};
`;
