import React from 'react';
import PropTypes from 'prop-types';

import StyledForm from './Form.style';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function Label({
  children,
}) {
  return <StyledForm>{children}</StyledForm>;
}

Label.propTypes = propTypes;

export default Label;
