import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import StyledSelect from './Select.style';

const defaultProps = {
  ariaLabel: '',
  block: false,
  className: '',
  customStyled: '',
  defaultValue: null,
  disabled: false,
  error: false,
  marginBottom: false,
  marginTop: false,
  name: '',
  onBlur() { },
  onChange() { },
  onFocus() { },
  outerRef: null,
  placeholder: false,
  readOnly: false,
  size: 'medium',
  type: '',
  value: null,
};

const propTypes = {
  ariaLabel: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  customStyled: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  outerRef: PropTypes.shape({}),
  placeholder: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  readOnly: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

class Select extends PureComponent {
  static defaultProps = defaultProps;

  static propTypes = propTypes;

  handleChange = (event) => {
    const { onChange, name, type } = this.props;
    const eventClone = { ...event };

    const value = type === 'number' ?
      Number(event.target.value) :
      event.target.value;

    eventClone.target = { name, value };

    onChange(eventClone);
  }

  render() {
    const {
      ariaLabel,
      children,
      placeholder,
      className,
      error,
      ...props
    } = this.props;

    const { defaultValue, value, ...selectProps } = props;

    // dynamically collect all data attributes for injecting on to input element
    const dataAttributes = Object.keys(props)
      .filter(key => key.indexOf('data-') === 0)
      .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});

    if (value || value === 0 || value === '') {
      selectProps.value = value;
    } else if (defaultValue || defaultValue === 0 || defaultValue === '') {
      selectProps.defaultValue = defaultValue;
    }

    selectProps.onChange = this.handleChange;

    return (
      <StyledSelect
        {...dataAttributes}
        {...selectProps}
        ref={this.props.outerRef}
        aria-label={ariaLabel}
        data-error={error}
        error={error}
        className={className}
      >
        {placeholder &&
          <option value="">{typeof placeholder === 'string' ? placeholder : 'Select one'}</option>
        }
        {children}
      </StyledSelect>
    );
  }
}

export default Select;
