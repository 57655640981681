import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroupStyled from './FormGroup.style';

class FormGroup extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { children } = this.props;

    return (
      <FormGroupStyled>
        {children}
      </FormGroupStyled>
    );
  }
}

export default FormGroup;
