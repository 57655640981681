import styled from 'styled-components';

import { above } from '../../../../styles/libs';

export default styled.label`
  display: block;
  align-items: center;
  width: 100%;
  padding: ${({ padding }) => padding || '0.5rem 0 0.375rem'};
  color: ${({ color, theme }) => color || theme.COLOR_TEXT_BODY};
  font-size: ${({ size }) => size || '0.8125rem'};
  font-weight: ${({ weight }) => weight};
  word-break: break-word;

  ${({ theme }) => above(theme.DESKTOP)`
    font-size: ${({ size }) => size || '0.875rem'};
  `};
  ${({ theme, responsiveHeight }) => responsiveHeight && above(theme.DESKTOP)`
    height: 3.5rem;
  `};
  ${({ flex }) => flex && `
    display: flex;
  `}
`;
